<template>
  <div
    class="
      card
      border border-2 border-gray-300 border-hover
      h-100
      cursor-pointer
    "
    v-loading.fullscreen.lock="loading"
  >
    <div class="d-flex justify-content-end w-100">
      <span
        @click="deleteWatchlist(watchlist.id)"
        class="
          svg-icon svg-icon-1
          p-2
          btn btn-md btn-icon btn-active-color-primary
        "
      >
        <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
      </span>
    </div>
    <div @click="routeToWatchlistDetails(watchlist.id)">
      <div class="card-header border-top border-bottom-0">
        <div class="card-title mt-4">
          <div class="symbol symbol-50px w-80px h-80px">
            <img
              class="w-100 h-100"
              :src="
                watchlist.watchListArtists.length
                  ? watchlist.watchListArtists[0].artist.imageUrl
                  : '/media/avatars/blank.png'
              "
              alt="image"
            />
          </div>
        </div>
        <div
          class="card-toolbar"
          v-if="watchlist.watchListTags && watchlist.watchListTags.length"
        >
          <span class="badge badge-light-primary fw-bolder me-auto px-4 py-3">{{
            watchlist.watchListTags[0].name
          }}</span>
        </div>
      </div>
      <div class="card-body p-9">
        <div class="fs-3 fw-bolder text-dark">
          {{ watchlist.name }}
        </div>
        <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7">Your Watchlist</p>
        <div class="d-flex flex-wrap mb-5">
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-7
              mb-3
            "
          >
            <div class="fs-6 text-gray-800 fw-bolder">
              {{ formatNumber(watchlist.totalSpotifyListeners) }}
            </div>
            <div class="fw-bold text-gray-400">Spotify Listeners</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              mb-3
            "
          >
            <div class="fs-6 text-gray-800 fw-bolder">
              {{ formatNumber(watchlist.totalFollowers) }}
            </div>
            <div class="fw-bold text-gray-400">Followers</div>
          </div>
        </div>

        <template v-if="watchlist.watchListArtists.length">
          <div class="symbol-group symbol-hover">
            <template
              v-for="(artist, index) in watchlist.watchListArtists"
              :key="index"
            >
              <div
                class="symbol symbol-35px symbol-circle"
                data-bs-toggle="tooltip"
                :title="artist.artist.name"
              >
                <img
                  v-if="artist.artist.imageUrl"
                  alt="Pic"
                  :src="artist.artist.imageUrl"
                />
                <span
                  v-else
                  class="symbol-label fw-bolder"
                  :class="`bg-primary text-inverse-primary`"
                  >{{ artist.artist.name.charAt(0) }}</span
                >
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNumberFormatter } from "@/common/composables/useNumberFormatter";

export default defineComponent({
  name: "WatchlistCard",
  components: {},
  props: {
    watchlist: {
      type: Object,
      default: () => {
        return {};
      },
    },
    icon: String,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { formatNumber } = useNumberFormatter();
    const loading = ref(false);

    const deleteWatchlist = (watchlistID) => {
      Swal.fire({
        title: "Delete this Watchlist?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            const isWatchlistDeleted = await store.dispatch(
              "UserModule/deleteWatchlist",
              watchlistID
            );
            loading.value = false;
            if (isWatchlistDeleted) {
              Swal.fire("Deleted!", "Watchlist has been deleted.", "success");
            } else {
              Swal.fire("Not Deleted!", "Watchlist does not exist.", "error");
            }
          }
        })
        .catch((e) => console.error(e));
    };

    const routeToWatchlistDetails = (watchlistID) => {
      router.push(`/watchlist/${watchlistID}`);
    };

    return {
      deleteWatchlist,
      formatNumber,
      loading,
      routeToWatchlistDetails,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-cursor {
  cursor: default;
}
</style>
