<template>
  <div class="watch-lists h-100" v-loading="loading">
    <div class="card mb-5 mb-xl-10" v-if="watchlist.length">
      <div class="card-body pt-9 pb-8 d-flex justify-content-between">
        <h2 class="heading">Your Watchlist</h2>
        <button
          @click="saveChanges"
          class="btn btn-sm btn-primary"
          :disabled="!watchlistPosition.length"
        >
          Save Changes
        </button>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <h3 class="heading">You currently have no watchlist.</h3>
      <a
        class="btn btn-sm btn-primary me-3"
        data-bs-toggle="modal"
        data-bs-target="#create_watchlist_modal"
        >Create Watchlist</a
      >
    </div>
    <div class="row g-6 g-xl-9">
      <div
        class="col-md-6 col-xl-4"
        v-for="(list, index) in watchlist"
        :key="list.id"
      >
        <WatchlistCard
          draggable="true"
          @dragstart="startDrag($event, list.id, index)"
          @drop="onDrop($event, list.id, index)"
          @dragover.prevent
          @dragenter.prevent
          :watchlist="list"
          icon="media/svg/brand-logos/plurk.svg"
        ></WatchlistCard>
      </div>
    </div>
  </div>
  <!--  <CreateWatchlistModal-->
  <!--    modal-ref="addWatchlistModalCreateWatchListComponent"-->
  <!--    ref="addWatchlistModal"-->
  <!--  ></CreateWatchlistModal>-->
</template>

<script>
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";

import WatchlistCard from "../components/WatchlistCard.vue";
// import CreateWatchlistModal from "@/user/components/CreateWatchlistModal.vue";
import { updateWatchlistPosition } from "../../api/user.api";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "watchlists",
  components: {
    WatchlistCard,
    // CreateWatchlistModal,
  },
  setup() {
    const store = useStore();
    const watchlist = computed(() => store.getters["UserModule/getWatchlist"]);
    const loading = ref(false);
    const addWatchlistModal = ref(null);
    const watchlistPosition = ref([]);

    onMounted(async () => {
      if (!watchlist.value.length) {
        loading.value = true;
        try {
          await store.dispatch("UserModule/fetchWatchlist");
        } catch (e) {
          console.error(e);
        } finally {
          loading.value = false;
        }
      }
    });

    const saveChanges = async () => {
      try {
        await updateWatchlistPosition(watchlistPosition.value);
        await store.dispatch(
          "UserModule/changeOrderOfWatchlist",
          watchlist.value
        );
        // await store.dispatch("UserModule/fetchWatchlist");
        ElNotification({
          title: "Saved!",
          message: "Watchlist Changes Saved.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Please try again.",
          type: "error",
        });
      }
    };

    const startDrag = (evt, watchlistID, position) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("watchlistID", watchlistID);
      evt.dataTransfer.setData("elementPosition", position);
    };

    const onDrop = (evt, dropElementID, dropElementPosition) => {
      const elementDraggedPosition = Number(
        evt.dataTransfer.getData("elementPosition")
      );
      const elementDraggedID = Number(evt.dataTransfer.getData("watchlistID"));
      const elementToSwap = watchlist.value[elementDraggedPosition];
      watchlist.value[elementDraggedPosition] =
        watchlist.value[dropElementPosition];
      watchlist.value[dropElementPosition] = elementToSwap;
      if (elementDraggedID !== dropElementID) {
        watchlistPosition.value = watchlistPosition.value.filter((position) => {
          return !(
            position.id === elementDraggedID || position.id === dropElementID
          );
        });
        watchlistPosition.value.push({
          id: elementDraggedID,
          position: dropElementPosition,
        });
        watchlistPosition.value.push({
          id: dropElementID,
          position: elementDraggedPosition,
        });
      }
    };

    return {
      watchlist,
      watchlistPosition,
      loading,
      addWatchlistModal,
      startDrag,
      onDrop,
      saveChanges,
    };
  },
});
</script>
