import { ref } from "vue";

export function useNumberFormatter() {
  const formattedNumber = ref("");
  const formatNumber = (followers) => {
    if (followers < 1e3) {
      return (formattedNumber.value = followers);
    } else {
      return Math.round(followers / 1e3).toLocaleString("de-DE") + "K";
    }
  };

  return {
    formatNumber,
  };
}
